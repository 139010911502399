<app-footnotes />
<div class="flex w-full flex-col text-white">
    <div class="bg-grey-extra-dark">
        <div class="inner-page-wrapper flex flex-col pb-24 pt-40 lg:flex-row lg:justify-between lg:py-40">
            <a class="flex h-max" [hyundaiRouterLink]="['/', dealer().slug]">
                <i class="icon-logo text-24"></i>

                <div class="mx-[11px] h-[32px] w-1 bg-grey-200"></div>
                <div class="flex flex-col font-medium">
                    <span class="text-14 leading-[14px]">Autohaus</span>

                    <span class="font-headline text-16 leading-[16px]">{{ dealer().name }}</span>
                </div>
            </a>

            <div class="mt-24 flex flex-col gap-24 lg:mt-0">
                @for (link of serviceLinks(); track $index) {
                    <a
                        class="group flex items-center gap-8 font-headline text-20 leading-30 lg:text-16 lg:leading-20"
                        [trackingElTarget]="link.href"
                        [trackingElLabel]="link.trackingLabel"
                        [hyundaiRouterLink]="link.href"
                        trackingElType="footer"
                        tracking
                        trackingEvent="engagement_click"
                    >
                        <i [ngClass]="[link.icon]"></i>
                        <span class="group-hover:underline">{{ link.label }}</span>
                    </a>
                }
            </div>

            <div class="my-24 h-1 w-full bg-grey-400 lg:hidden"></div>

            <div class="flex flex-col gap-24 font-headline text-16 leading-20 hover:*:underline">
                @if (dealer().hasCampaigns) {
                    <a [hyundaiRouterLink]="dealer().pages.campaigns">Aktuelle Aktionen</a>
                }
                @if (dealer().partsStore && dealer().pages.shop) {
                    <a [hyundaiRouterLink]="dealer().pages.shop" [queryParams]="{ utm_medium: 'dealer' }">
                        Zubehör-Katalog
                    </a>
                }
                @if (dealer().hasSales && dealer().pages.businessCustomers) {
                    <a [hyundaiRouterLink]="dealer().pages.businessCustomers">Gewerbekunden</a>
                }
                <a [hyundaiRouterLink]="dealer().pages.aboutUs" [fragment]="ABOUT_US_TABS_JOBS">Karriere</a>
                <a [hyundaiRouterLink]="dealer().pages.warranty">Garantie</a>
            </div>

            <div class="my-24 h-1 w-full bg-grey-400 lg:hidden"></div>

            <div class="lg:min-w-[220px]">
                @if (socialLinks().length > 0) {
                    <div class="flex flex-col gap-16">
                        <p class="text-14 leading-20 lg:text-16 lg:leading-27">
                            Folgen Sie uns für aktuelle Angebote und News
                        </p>
                        <div class="flex gap-16">
                            @for (link of socialLinks(); track $index) {
                                <a class="group" [hyundaiRouterLink]="link.href">
                                    <i
                                        class="flex items-center gap-8 font-headline text-[32px] leading-30 group-hover:opacity-75"
                                        [ngClass]="[link.icon]"
                                        [attr.aria-label]="link.label"
                                        role="link"
                                    ></i>
                                </a>
                            }
                        </div>
                    </div>
                }
            </div>

            @if (socialLinks().length > 0) {
                <div class="mb-8 mt-24 h-1 w-full bg-grey-400 lg:hidden"></div>
            }
        </div>
    </div>
    <div class="bg-grey-400">
        <div class="inner-page-wrapper py-24 lg:py-40">
            <div class="flex flex-col gap-16 text-14 leading-20 xl:flex-row xl:gap-40">
                <p>© Hyundai Motor Deutschland GmbH, {{ currentYear }}.</p>
                <div class="contents hover:*:underline">
                    <a [hyundaiRouterLink]="getGlobalImprintUrl">Impressum</a>
                    <a [hyundaiRouterLink]="getGlobalPrivacyUrl">Datenschutz</a>
                    <a [hyundaiRouterLink]="getGlobalLegalUrl">Rechtliche Hinweise</a>
                    <button
                        class="ot-sdk-show-settings !border-none !bg-transparent !p-0 !text-left !text-14 !leading-20 !text-white hover:!bg-transparent hover:!underline"
                        id="ot-sdk-btn"
                    >
                        Cookie-Einstellungen
                    </button>
                    <a hyundaiRouterLink="https://www.hyundai.com/de/de.html">Hyundai Deutschland</a>
                </div>
            </div>

            <div class="mt-32 h-1 w-full bg-grey-300"></div>

            <div class="mt-32 flex flex-col gap-8 text-14 leading-20">
                <p>
                    Alle Angaben und Abbildungen sind als unverbindlich zu betrachten und stellen eine annähernde
                    Beschreibung dar. Fahrzeugabbildungen enthalten z. T. aufpreispflichtige Zusatzausstattungen.
                </p>
                <p>
                    Die Informationen erfolgen gemäß der Pkw-Energieverbrauchskennzeichnungsverordnung. Die angegebenen
                    Werte wurden nach dem vorgeschriebenen Messverfahren WLTP (Worldwide harmonised Light-duty vehicles
                    test Procedure) ermittelt. Der Kraftstoffverbrauch und der CO₂-Ausstoß eines Pkw sind nicht nur von
                    der effizienten Ausnutzung des Kraftstoffs durch den Pkw, sondern auch vom Fahrstil und anderen
                    nichttechnischen Faktoren abhängig. CO₂ ist das für die Erderwärmung hauptsächlich verantwortliche
                    Treibhausgas.
                </p>
                <p>
                    Ein Leitfaden über den Kraftstoffverbrauch und die CO₂-Emissionen aller in Deutschland angebotenen
                    neuen Pkw-Modelle ist unentgeltlich einsehbar an jedem Verkaufsort, an dem Pkw ausgestellt oder
                    angeboten werden. Der Leitfaden ist auch
                    <a class="underline" href="https://www.dat.de/" target="_blank">hier</a>
                    abrufbar.
                </p>
            </div>
        </div>
    </div>
</div>
